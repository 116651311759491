 <template>
   <div>
	
	<div style="width: 100%; height: 240pt !important; overflow-x: hidden; overflow-y: auto;">
		
		<div v-if="weeklies && weeklies.limits && shares && shares.length && weekShareLimitList">
	    <div style="width: 28%; float: left;">
			<div class='fieldTop fieldLeft'/>
			<div class='fieldTop fieldLeft'>Timeslots</div>
			<div v-for="(s,si) in shares" :key="'s'+si" >
						<div class='fieldLeft'>
					
						<InputTime v-model="s.from" @changed="adjustTimeTo(s)"/> - <InputTime v-model="s.to" :min="s.from"/>
						</div>
			</div>
		</div>	
		
		<div v-if="weeklies && weeklies.limits && weekShareLimitList" style="width: 72% !important; float: left; overflow-y: hidden; overflow-x: auto;">
			<div class="dontWrap">
				<div v-for="(w,wi) in weeklies.limits" :key="'w'+wi" class="fieldTop bold">
			    W-{{w.week}} 
				</div>
			</div>
			<div class="dontWrap">
				<div v-for="(w,wi) in weeklies.limits" :key="'wsum'+wi" class="fieldTop">
			   		<input @focus="$event.target.select()" type="text" :value="w.budget" @change="changedWeekly($event,w)" size="1" class="numeric field noInp"/>
				</div>
			</div>
			
			<div v-for="(s,si) in shares" :key="'s'+si" class="fieldRow">
				<div class="dontWrap">
					<div v-for="(w,wi) in weeklies.limits" :key="'winp'+wi" class="field">
						<input @focus="$event.target.select()" type="number" 
						       :disabled="!w.budget || !editMode"
							   @dblclick="assign(w.week,s.id)"
						       :tabIndex="tabIndex[w.week][s.id]"
						       class="numeric field inp" v-model="weekShareLimitList[w.week][s.id]" @input="changed(w.week,s.id)"/>
					</div>
					
					<div class="field" style="margin-left: 4pt;">
						<input type="number" 
						       :disabled="true"
						       class="numeric field noInp" :value="sumH(s.id)"/>
					    	   						
					</div>
					
					<div class="fieldXS">
						<button :style="getAppStyle()" class='button myButtonXS' @click="deleteShare(s.id)"><mdicon :width="14" name="close" /></button>					
					</div>	
				</div>
			</div>	
			
			<div class="dontWrap">
				<div v-for="(w,wi) in weeklies.limits" :key="'wtot'+wi" class="fieldTop">
			   		<input type="text" :disabled="true" :value="sum(w.week)" size="1" class="numeric field bold noInp" :style="matches(w.week)==true?'color: #0a0':'color: #c00'"/>
				</div>
			</div>
		
		</div>
		
		</div>
		
		<div v-if="weekShareLimits && weekShareLimits.tolerance != null" class="dontWrapIB" style='height: 21pt; width: 100%;' >
				<span class='smallTextSlider bold' style='width: 25%;'>Tolerance: {{formatPercent(weekShareLimits.tolerance)}}</span>
		        <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 70%; display: inline-block;'>
				<vue-slider :interval="5" :marks=false min=0 :max="50" v-model="weekShareLimits.tolerance"></vue-slider>
				</div>
		</div>
		
		<div v-if="!fromEditor" style='display: table; position: absolute; bottom: 0; width: 100%;'>
				<button title="save" :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveWeeklyShares(weekShareLimits)'>SAVE</button>
				<button title="create new timeslot" :style="getAppStyle()+'float: right;'" class='button myButton' @click='add'><mdicon :width="16" name="plus" /></button>
				
				<button title="check booked budged against this definition" :style="getAppStyle()+'float: right;'" class='button myButton' @click="doublecheck(weekShareLimits)">
					<mdicon v-if="editMode" :width="18" name="eye-check-outline" />
					<mdicon v-else :width="18" name="eye-off-outline" />
				</button>
								
				<button title="calculate" :style="getAppStyle()+'float: right;'" class='button myButton' @click='calculate'><mdicon :width="18" name="calculator" /></button>
				<button title="reload" :style="getAppStyle()+'float: right;'" class='button myButton' @click='reload'><mdicon :width="16" name="reload" /></button>
		</div>
		
	</div>
	
	<AIndividualChart v-if="!fromEditor" ref='chartView'>
	</AIndividualChart>
  </div>
</template>

<script>
import {HTTP, WSLURL, fwAPI, userAPI, invAPI, bngAPI, opsAPI, reportAPI, inventoryInlineAPI, setReload, formatNumber,showError} from '@/variables.js';
import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { formatPercent, format } from '@/Numbers.js';
import InputField from '@/components/booking/InputField';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
import InputTime from '@/components/inputElements/InputTimeHHMM';
//import InputFieldS from '@/components/booking/InputFieldS';
import AIndividualChart from '@/components/AIndividualChart';
import Switch from '@/components/Switch';
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
export default {
  name: "WEEKLIMITS",
   components : {
	  //InputFieldS, 
	  AIndividualChart
	  , InputTime
	  //'app-switch': Switch
  },
  props: {
	  value: Object,
	  sharesInp: Array,
	  weeklies: Array,
	  placementId:{ type: Number, default: 0},
	  budget: { type: Number, default: 0},
	  timeFrom: { type: Number, default: 0},
	  timeTo: { type: Number, default: 0},
	  fromEditor: { type: Boolean, default: false}
  },
  data(){
    return {
    	formatPercent, getAppStyle, format,
    	weekShareLimits: this.value,
		weekShareLimitsSave: this.value,
		weekShareLimitsInput: null,
		weekShareLimitList: null,
		weekliesChanged: false,
		editMode: true,
		shares: [],
		nextShareId: 1,
		tabIndex: null,
		sumBudget: {},
		
		fits: [],
		ready: false
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
		fmt( val)
    	{
       		return formatNumber( val );
    	},
		short(s)
		{
			if ( s.startsWith("That Time is "))
			{
				return s.substr(13);
			}
			if ( s.startsWith("That "))
			{
				return s.substr(5);
			}
			return s;
		},
	   parse( s)
	   {
			let arr = s.split(":");
	        let val = 0;
	        if ( arr )
	        {
	        	if ( arr.length >= 1 )
	        	{
	        		val = parseInt(arr[0]) * 3600;
	        	}
	        	if ( arr.length >= 2 )
	        	{
	        		val += parseInt(arr[1]) * 60;
	        	}
	        	if ( arr.length >= 3 )
	        	{
	        		val += parseInt(arr[2]);
	        	}
	        }
			return val;
	   },
	   adjustTimeTo( s )
	   {
			if ( s.from >= s.to )
			{
			    let hh = Math.round((s.from - (s.from % 3600))/3600);
				
				s.to = hh*3600+3599;
			}
	   },
	   parseTime( s0 )
	   {
		    let s = this.short( s0);
			
			if ( s.length == 14)
			{
				let fromStr = s.substring( 0, 5);
				let toStr = s.substring( 8, 13);
				
				return { from: this.parse( fromStr), to: this.parse( toStr)};
			}
			return null;
	   },
	   assign( w, s)
	   {
			this.weekShareLimitList[w][s] = 0;
			let x = this.sum( w, s);
			let y = parseFloat(this.weeklies.limits.find( p=>p.week==w).budget);
			this.weekShareLimitList[w][s] = y-x;
			this.changed( w, s)
	   },
       changed( w, s)
	   {
			if ( isNaN( parseFloat(this.weekShareLimitList[w][s])))
			{
				this.weekShareLimitList[w][s] = 0;
			}
			
			this.sum( w);
			this.save();
	   },
	   changedWeekly( e,w)
	   {
			w.budget = parseFloat(e.target.value);
			this.weekliesChanged = true;
			this.emit("weeklies", this.weeklies)
	   },
	   save()
	   {
		if ( this.editMode)
		{
			this.weekShareLimitsSave = {...this.clean(this.weekShareLimits)};
			this.$emit('input', this.clean(this.weekShareLimitsSave));
		}	
	   },
	   matches( w)
	   {
		    let x = this.sum( w);
			let y = parseFloat(this.weeklies.limits.find( p=>p.week==w).budget);
			
			this.fits[w] = Math.abs(x-y) < 0.01;
			return this.fits[w];
	   },
	   sum( w, s)
	   {
			this.sumBudget[w] = Object.values(this.weekShareLimitList[w]).reduce((a,b)=>parseFloat(a)+parseFloat(b));
			//this.$forceUpdate();
			return this.sumBudget[w];
	   },
	   sumH( si)
   	   {
			let s = 0;
		    for ( let x in this.weekShareLimitList)
			{
				s += parseFloat(this.weekShareLimitList[x][si]);
			}
   			
   			//this.$forceUpdate();
   			return s;
   	   },
	   reload() {
		   if ( this.weekShareLimitsInput )
		   {
		   		this.init( this.weekShareLimitsInput);
				this.weekShareLimitsInput = null;
				this.editMode = true;
		   }
		   else
		   {
				this.init( );
		   }
		   this.$forceUpdate();
	   },
	   doublecheck( ws)
	   {
			let that = this;
			if ( ! this.weekShareLimitsInput )
			{
				this.weekShareLimitsInput = ws;
			}
			else
			{
				that.init( that.weekShareLimitsInput);
				that.editMode = true;
				this.weekShareLimitsInput = null;
				return;
			}
			for ( let wlsi in ws.limits)
			{
				let limit = ws.limits[ wlsi];
				//console.log( JSON.stringify( limit));	
				if ( !this.weekShareLimitList[ limit.week])
				{
					this.weekShareLimitList[ limit.week] = {};
				}
				limit.budget = this.weekShareLimitList[ limit.week][ limit.shareId];
				//console.log( limit.shareId+ " --> " +JSON.stringify( this.shares));	
				let share = this.shares.find( p=>p.id==limit.shareId); 
				//console.log( limit.shareId+ " --> " +JSON.stringify( share));		
				limit.from = share.from;
				limit.to = share.to;
				//delete limit.shareId
				//console.log( JSON.stringify( limit));		
			}
			
			HTTP.post( reportAPI+"/doubleCheckWeekCombinedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId, ws)
				    .then( response => 
				{ 
					that.weekShareLimits = response.data;
					that.editMode = false;
					that.init( that.weekShareLimits);
					
					//that.$toast.success("Week limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
				}).catch(e => {
				   	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
				});
	   },
	   calculate()
	   {
			let that = this;
			
			this.$iosConfirm( "re-calculate weekly shares from existing bookings").then(x => { 
			
			
				HTTP.post( reportAPI+"/calcWeekCombinedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId)
				    .then( response => 
				{ 
					that.value = response.data;
					
					that.init();
					
					//that.$toast.success("Week limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
				}).catch(e => {
				   	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
				});
			});
	   },
	   loadData() {
		   let that = this;
		   HTTP.post( reportAPI+"/getWeekCombinedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId)
	        .then( response => 
	        { 
				that.weekShareLimits = response.data;
				
	        	//that.$toast.success("Week limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
	        }).catch(e => {
	        	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
	        });
	   },
	   equals( a, b)
	   {
		 return JSON.stringify(a) == JSON.stringify(b)  
	   },
	   containsShare( from)
	   {
			return this.shares.find( p=>p.from===from);	
	   },
	   clean( ws )
	   {
			for ( let wlsi in ws.limits)
			{
				let limit = ws.limits[ wlsi];
				//console.log( JSON.stringify( limit));	
				if ( !this.weekShareLimitList[ limit.week])
				{
					this.weekShareLimitList[ limit.week] = {};
				}
				limit.budget = this.weekShareLimitList[ limit.week][ limit.shareId];
				let share = this.shares.find( p=>p.id===limit.shareId); 
				//console.log( limit.shareId+ " --> " +JSON.stringify( share));		
				limit.from = share.from;
				limit.to = share.to;
			}
			return ws;
	   },
	   saveWeeklyShares( ws)
	   {
			let that = this;
			for ( let wlsi in ws.limits)
			{
				let limit = ws.limits[ wlsi];
				//console.log( JSON.stringify( limit));	
				if ( !this.weekShareLimitList[ limit.week])
				{
					this.weekShareLimitList[ limit.week] = {};
				}
				limit.budget = this.weekShareLimitList[ limit.week][ limit.shareId];
				//console.log( limit.shareId+ " --> " +JSON.stringify( this.shares));	
				let share = this.shares.find( p=>p.id==limit.shareId); 
				//console.log( limit.shareId+ " --> " +JSON.stringify( share));		
				limit.from = share.from;
				limit.to = share.to;
				//delete limit.shareId
				//console.log( JSON.stringify( limit));		
			}
			let weekShareStruct = { weekLimits: that.weeklies, weekShareLimits: ws, weekliesChanged: that.weekliesChanged};
			//alert( JSON.stringify( weekShareStruct ))
			HTTP.put( reportAPI+"/saveWeeklyShares/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId, weekShareStruct)
	        .then( response => 
	        { 
				that.value = response.data;
	        	that.$toast.success("Weekly shares & limits saved", 'Ok', { timeout: 1000, position: "topRight" });
	        }).catch(e => {
				this.init();
				that.$toast.error(e.response, 'Ok', { timeout: 1000, position: "topRight" });
	        	showError( that.$toast, "save limits", e)
	        });
	   },
	   add()
	   {
			try
			{
			    let max = this.shares && this.shares.length ? this.shares[ this.shares.length-1].to + 60 : this.timeFrom;
				let nwShare = { id: this.nextShareId++, from: max, to: max+3600-60};
				this.shares.push( nwShare);
				for ( let wi in this.weeklies.limits)
				{
					let weekly = this.weeklies.limits[wi];
					if ( !this.weekShareLimitList[ weekly.week])
					{
						this.weekShareLimitList[ weekly.week] = [];
					}
					this.weekShareLimitList[ weekly.week][ nwShare.id] = 0;
					this.weekShareLimits.limits.push({ week: weekly.week, from: nwShare.from, to: nwShare.to, budget: 0, shareId: nwShare.id});
				}
				this.save();
			} catch (e)
			{
				console.log(e)
			}
	   },
	   deleteShare( id)
   	   {
			this.shares = this.shares.filter( p=>p.id !== id);
			this.weekShareLimits.limits = this.weekShareLimits.limits.filter( p=>p.shareId != id);
   			for ( let wi in this.weeklies.limits)
   			{
   				let weekly = this.weeklies.limits[wi];
   				
   				this.weekShareLimitList[ weekly.week][ id] = 0 ;
   			}
			
   	   },
	   init( ws )
	   {
			this.ready = false;
			if ( ws )
			{
				this.weekShareLimits = ws;
			}
			else
			{
				this.weekShareLimits= {...this.value};
			}
			this.shares = [];
			this.nextShareId = 1;
		  	if ( this.fromEditor )
		  	{
				this.loadData();   
			}
			else
			{
				if ( (!this.sharesInp || !this.sharesInp.length) && (!this.weekShareLimits || !this.weekShareLimits.limits || !this.weekShareLimits.limits.length))
				{
					this.weekShareLimits = { tolerance: this.weeklies.tolerance, weekStart: this.weeklies.weekStart, limits: []};

					for ( let wi in this.weeklies.limits)
					{
						let weekly = this.weeklies.limits[wi];
						
						this.weekShareLimits.limits.push({ week: weekly.week, from: 6*3600, to: 6*3600+59*60, budget: 0});
					}
					
				}
				else if ( (!this.weekShareLimits || !this.weekShareLimits.limits || !this.weekShareLimits.limits.length) && this.weeklies && this.weeklies.limits && this.sharesInp)
				{
					this.weekShareLimits = { tolerance: this.weeklies.tolerance, weekStart: this.weeklies.weekStart, limits: []};
					
					for ( let wi in this.weeklies.limits)
					{
						let weekly = this.weeklies.limits[wi];
						
						for ( let si in this.sharesInp)
						{
							let share = this.sharesInp[si];
							let timeStruct = this.parseTime( share.name);
							
							if ( timeStruct )
							{
								this.weekShareLimits.limits.push({ week: weekly.week, from: timeStruct.from, to: timeStruct.to, budget: 0});
							}
						}
					}
				}
				else
				{
					for ( let wi in this.weeklies.limits)
					{
						let weekly = this.weeklies.limits[wi];
						let exist = this.weekShareLimits.limits.find( p=>p.week == weekly.week);
						let oneWeek = this.weekShareLimits.limits.find( p => p.week > 0 ).week;
						
						if ( !exist)
						{
							//alert( oneWeek)
							let existingShares = this.weekShareLimits.limits.filter( p=>p.week == oneWeek );
							//alert( JSON.stringify(existingShares))
							for ( let si in existingShares)
							{
								let xShare = existingShares[si]
								this.weekShareLimits.limits.push({ week: weekly.week, from: xShare.from, to: xShare.to, budget: 0});
							}
						}
					}
				}
				
			}
			this.weekShareLimitList = [];
			this.tabIndex = [];
		
			for ( let wsli in this.weekShareLimits.limits)
			{
				let limit = this.weekShareLimits.limits[ wsli];
				if ( !this.containsShare( limit.from))
				{
					this.shares.push( { id: this.nextShareId++, from: limit.from, to: limit.to});
				}
				let share = this.containsShare( limit.from);
				if ( !this.weekShareLimitList[ limit.week])
				{
					this.weekShareLimitList[ limit.week] = {};
					this.tabIndex[ limit.week] = {};
				}
				this.weekShareLimitList[ limit.week][ share.id] = limit.budget;
				limit.shareId = share.id;
			}
			let idx = 0;
			for ( let wlsi in this.weekShareLimits.limits)
			{
				let limit = this.weekShareLimits.limits[ wlsi];
				
				for ( let shi in this.weekShareLimitList[ limit.week])
				{
					this.tabIndex[ limit.week][ shi] = idx++;
				}
				this.sum( limit.week)
			}

			this.shares.sort((a,b)=>a.from-b.from )
			this.ready = true;
	   }
  },
  updated() {
    
  },
  computed: {
	
  },
  beforeDestroy() {

  },
  created() {
  	 this.init();
  },
   watch:
   {
	  value:  function() { 
		  		this.init();
			  },
	  weekShareLimits: function() { if ( this.ready && !this.equals(this.weekShareLimitsSave, this.weekShareLimits)) this.save()},
   }
}
</script>
<style  scoped>
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-block;
}
.bold {
	font-weight: bold;
}
.smallText {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextSlider {
  width: 60%;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonXS {
	//width: 18%;
    padding: 0px 0px;
    outline: none;
    border-radius: 3px;
    height: 16pt;
    font-size: 10pt;
    background-color: #eef;
    border: none;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}  
.fieldLeft {
	display: block; 
	width: 76pt !important;
	font-size: 9pt;
	height: 12pt;
	overflow: hidden;
	white-space: nowrap; /* Don't forget this one */
}
.field {
	display: inline-flex; 
	width: 30pt;
	font-size: 9pt;
	height: 12pt;
}
.fieldXS {
	display: inline-flex; 
	font-size: 9pt;
	height: 12pt;
}
.fieldRow {
	display: block; 
	
	font-size: 9pt;
	height: 12pt !important;
}
.fieldTop {
	display: inline-block; 
	text-align: center;
	width: 30pt;
	font-size: 9pt;
	height: 12pt;
}
.numeric {
	text-align: right;
}
.inp {
	border: 1px solid #aaa;
}
.noInp {
	border: none;
}
</style>