<template>
	<div class='BMadbreak1'  >
		<div v-if="clashes" style='font-size: 8pt; font-weight: normal;' class="hatch">
			 <div style='text-align: center; white-space: nowrap; display: inline-block; width: 100% !important; '>
				<div v-if="breakInfo.booked" style='font-size: 8pt;display: inline-block;'>{{breakInfo.booked}}</div>
			    <div v-if="breakInfo.unplaced" :style="'font-size: 8pt;display: inline-block; color: '+options.unplacedColor+' !important;'">({{breakInfo.unplaced}})</div>
			</div>
		</div>
		<div v-else-if="breakInfo.grp" style='font-size: 8pt; font-weight: normal;'>
					
					<GRate :title="'GRP ' + breakInfo.grp"
		              :value="breakInfo.grp" :color="options.grpColor"
		              :max=3 style='width: 100%;'
					>
					 <div style='text-align: center; white-space: nowrap; display: inline-block; width: 100% !important; '>
						<div v-if="breakInfo.booked" style='font-size: 8pt;display: inline-block;'>{{breakInfo.booked}}</div>
					    <div v-if="breakInfo.unplaced" :style="'font-size: 8pt;display: inline-block; color: '+options.unplacedColor+' !important;'">({{breakInfo.unplaced}})</div>
					</div>
					</GRate>
				</div>
		<div v-else>
			<div v-if="breakInfo.booked" style='font-size: 8pt;display: inline-block;'>{{breakInfo.booked}}</div>
			<div v-if="breakInfo.unplaced" :style="'font-size: 8pt;display: inline-block; color: '+options.unplacedColor+' !important;'">({{breakInfo.unplaced}})</div>
		</div>

   </div>
</template>
<script>
import { getAppStyle } from '@/AppStyle.js';
import GRate from '@/components/GRate';
export default {
  props: {
	  breakInfo: { type: Object },
	  clashes: { type: Boolean, default: false },
	  options: { type: Object }
  },
 components : {
	 GRate
  },
  data () {
    return {
    	getAppStyle
    }
  }
 }
</script>
<style lang="scss" scoped>
.BMadbreak1 {
	display: inline-block; 
	width: 66%;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 2pt;
   
    margin: 0pt;
    height: 100%;
    margin: 0pt;
}
.hatch
{
	background: repeating-linear-gradient(-45deg, #ddd, #ddd 5px, white 5px, white 10px);
}
</style>