var settings = {};

export function store( obj )
{
	settings = obj;
} 


export function restore( )
{
	return settings;
} 

export function getRawKey(channel, grid, adspace, breaktype, day)
{
    return grid+":"+channel+":"+adspace+":"+breaktype+":"+day;
}

export function getRawKeyById( breakId, day)
{
	return breakId+"."+day;
}
