<template>
  <div style='margin: 0pt; height: 100%; width:100%;'>
 
  <div style='width: 100%;' :style='getAppStyle()'>
     <div style='width: 100%;'>
       <div class="xmodal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft' :style='getAppStyle()'>
            	Day-Grid for {{placement.name}}
            	
                </div>
               

            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div>
          <slot name="body">
            <div style='width: 100%;' >
             <div class='transition' :style='getControlStyle()'>
	    		<!-- CONTROLS -->
			    <div style='float: top; width: 100%; vertical-align: middle;' class='smaller'>
			        <!-- FILTERS -->    
				    
				    <div v-if="true">
				    <span class='dontWrap smallText bold'>Dates:</span>
					<InputDatePickM :from="dateFrom" :to="dateTo" @change='setDates' v-model="dates"/>
			 		</div>
				    
				    <span class='dontWrap smallText bold'>Pricegroups:</span>
					<GSelect :options="allPG" v-model="selectedPG" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Timeslots:</span>
					<GSelect :options="allTimeslots" v-model="selectedTimeslots" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
			        <br/>
			        <span class='dontWrap smallText bold'>Only booked breaks/programs:</span>
			        <app-switch  v-model="onlyBooked" :checked="onlyBooked"/> 
			        
			        
			        <div v-show="markedSpots">
			        	<button @click='bookMarked($event)' :style="getAppStyle()" class='button myButton'>Book {{markedSpots}} Spots</button>
			        	<button @click='cancelMarked()'     :style="getAppStyle()" class='button myButton'>Cancel {{markedSpots}} Breaks</button>
			        </div>
			       
			    </div> <!-- CONTROLS -->
    		</div>
    		</div>
    
    		<!-- MAIN -->
            
            <div :style="getAppStyle()+'width: 57%; height: calc(83.5vH); overflow-y: scroll; position: relative; font-size: 12px;'">
 	                   
                <div style='width: 100%;' v-if="!allReady" >
					LOADING ... 
					
				</div>
				<div style='width: 100%;' v-else >
					
				    <div style='display: flex; border-bottom: 1px solid #aaa; position: relative; width: 100%; ' 
				         v-for="(d,di) in uniqueDays" :key="'day_'+di">
						<div style='position: sticky; top: 0; height: 100% !important;  display: block; width: 10% !important;'>
							{{ d }}
						</div>
						
				<div style='position: relative; top: 0; width: 89.5%;'>	
			    <div v-for="(dg,dgi) in filter(grid.dayStructure[d])" :key="'dayGrid_'+di+'_'+dgi" :class='getSlotClass(dg, dg)'>
		 	    <div class="RDOpenProgram" >
			 		<div class="RDProgram" :style="getAppStyle()+'height: 21pt; background-color: '+getBGColor('#eef','#226')+' !important; display: block !important; border: 1pt outset grey; position: sticky; top: -1px;'">
			 		    <table style='table-layout: fixed;' width='100%' border="0">
			 		    <tr>
				 		    <td width="25%" style='width: 100% !important; display: block !important; position: relative; text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden;'>
				 		        
				 		         {{ printTime2(dg.dayGrid.time)}} 
				 				<span v-show='dg.diff' class="RDProgramTime2">
					 				({{ printTime2(dg.dayGrid.time)}}
					 			</span>
					 	    </td>
					 	    
					 	    <td>
					 	        <span class="RDProgramTitle" :title='dg.name'
					 	              @click="openGrid(grid)"
					 	              
					 	              style='cursor: pointer; width: 100% !important; display: flex !important; text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden;'>
											{{dg.dayGrid.program}}
						 			
						 		</span>
					 	    </td>
					 	    <td width="25%">
					 	        <span class="RDGridDurProgress">
			 			        <GProgress :height="11" :percentage="Math.round(100*sumGrid( dg.breakList)/dg.dayGrid.capacityInMS)"/>
					    		</span>
					 	    </td>
			 		    </tr>
			 		    <tr>
				 		    <td>
				 		      <span class="RDBreakDur" style='margin: 0pt; width: 100% !important; display: inline-flex !important;text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden;' title='usedTime vs. available ad-time in grid'>

					 		  </span>
				 		    </td>
				 		    <td  class='dontWrap'> 
				 		       

					 	     
						 	</td>
						 	<td>
						 	
						     
				 		    </td>
			 		    </tr>
			 		    </table>
			 		   
			 		</div>
		
		 		<div  v-for="(brk) in dg.breakList" :key="brk.key" >
		 				<div class='RDBreak' style='height: 15pt !important;' @click="markBreak( brk)">
		 					
			 				<span class="RDBreakTime">
			 				    
			 				    <!--  X{{brk.brk.identifier}}X -->
				 				{{printTime2(brk.space.estStarttime)}}
				 				
				 			</span>
				 			
					 		<span class="RDBreakTitle">
					 		    <!-- {{ brk.brk.identifier}} -->
					 		    
					 		    {{brk.type.shortname}}-{{ brk.brk.code }}
					 			{{ getBreakCode( brk.space, brk.type, brk) }}
					 			
					 		</span>
				 			<!-- 
					 		 -->
					 		
					 		<span class="RDBreakDur">
					 			{{ printTimeMMSS( sum( brk.spots)) }}
					 		</span>
					 		<span v-if="brk.brk.capacityInMS" class="RDBreakDur">
					 		/  {{ printTimeMMSS( brk.brk.capacityInMS/1000) }}
					 		</span>
					 		
					 		<span class="button" :style="getAppStyle()+'float: right; width: 20pt; border: none; padding: 0, margin: 0; height: 14pt !important;'" >
								 <mdicon height="16" v-if="brk.marked" name="checkbox-marked-circle" />
								 <mdicon height="16" v-else name="checkbox-blank-circle-outline" />
						     </span>
					 		<div v-if="brk.brk.capacityInMS" class="RDBreakDurProgress" style='margin-top: 2pt;'>
					 			
					 			<GProgress :height="9" :treshold="Math.round(getTreshold( brk))" :percentage="Math.round(100000*sum( brk.spots)/brk.brk.capacityInMS)"/>
					 		</div>
							

		 				</div>
						<SimpleSpotList v-if="brk.spots"
						    :ref="brk.brk.id"
						    @loaded="loaded()"
							@deleteSpot=deleteSpot
							@moveSpots=moveSpots 
							:grid=dg
							:name="brk.key"
							:identifier="brk.brk.id"
							:fontSize="10"
							:dayId="'1'"
							:user=user
							:selectedIN="''"
							:spots="brk.spots?brk.spots:[]">
					    </SimpleSpotList>
		 		</div>
		 		</div>
		 	</div>
	</div>
				   </div>
		        </div>

			
			</div> <!-- mainContent -->
			
	      </slot>
	    </div> <!-- modal-body -->
	  </div> <!-- modal-container -->  	  
	  
	 </div> <!-- modal-wrapper -->  
   
    <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="openBreak($event, contextData.key)">
          Open Break {{contextData.key}}
        </ContextMenuItem>
        <ContextMenuItem  @clicked="openDetailPlan( contextData.channel, contextData.day)">
          Open RunDown Screen {{contextData.day}}
        </ContextMenuItem>
       
       </template>
    </ContextMenu>
    <BreakView v-if="false" ref='breakView' :reference=selectedRef :time="new Date().getTime()" 
        :options=options
        @saveUser=saveUser
        @deleteSpot="deleteSpot"
        @setTypeForSpots="setTypeForSpots"
        @close="closeBreakView()">
    </BreakView>
    

   
 </div>
</template>

<script>
import GFWTreeView from '@/components/GFWTreeView';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputDatePick from '@/components/inputElements/InputDatePick';
import InputTime from '@/components/inputElements/InputTime2';
//import SimpleSpotList from '@/components/SimpleSpotList';
//import BreakView from '@/components/BreakView';
import GProgress from '@/components/misc/GProgress';
import BreakView from '@/components/BreakView';
import BookingGridBreak from '@/components/booking/BookingGridBreak';
import GSelect from '@/components/misc/GSelect';
import SimpleSpotList from '@/components/SimpleSpotList3';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import {HTTP, fwAPI, invAPI, bngAPI, setReload, formatNumber} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGColor, getBGStyle2 } from '@/AppStyle.js';
import {fmtTimePart, printTimeHHMM, printTimeMMSS, printTime2 } from '@/basicTimeFN.js';
import InputDatePickM from '@/components/inputElements/InputDatePickM'; 
import { getDataByName}  from '@/utils.js';
import { getRawKey } from '@/bookMan.js';
import { setGoBack } from '@/breadCrumb.js';
import { openView } from '@/utils.js';
var numeral = require('numeral');
var timers = [];
export default {
  name: 'GFW BookingDayGrid',
  components : {
    ContextMenu, ContextMenuItem, BreakView, SimpleSpotList, GProgress, GSelect, 'app-switch': Switch, InputDatePickM
  },
  props: {
  	spotsPlacementTotal: {type: Number, defaultValue: 0}, 
  	budgetPlacementTotal: {type: Number, defaultValue: 0}, 
  	grpPlacementTotal: {type: Number, defaultValue: 0}, 
  	rateCardPlacementTotal: {type: Number, defaultValue: 0}, 
  	targetType: Object,
    gridInput: Object,
    businessType: Object,
    selectedBookStatus: Object,
    duration: String,
    options: Object,
    targetGroup: Object,
    markBreaks: Array,
    spotsDay: Array,
    spotsGrid: Array,
    spotsUnplacedBreak: Array,
    spotsBreak: Array,
    consumedGrid: Array,
    consumedBreak: Array,
    spotsBreakTotal: Array,
    invalidSpots: Array,
    user: Object,
    invalidSpotsChanged: {type: Number, defaultValue: 0},
    allBookingStats: Array,
    placementInfo: Object,
    fromDate: String,
    untilDate: String,
    inputWeekdays: {type: Number, defaultValue: 127},
    inputBreakTypeIds: Array,
    time: Number
  },
  data () {
    return {
      placement: this.placementInfo.placement,
      placementFrom: null,
      dayHasBreak: null,
      allReady: false,
      loadedAlready: 0,
      uniqueDays: [],
      updateCounter: 0,
      grid: {},
      placementTo: null,
      openThisModule: String,
      inventory: {},
      gridData: [],
      mediaSet: {},
      channels: {},
      channelDiffs: [],
      timeSlots: {},
      
      breakKeys: {},
      dateFrom: "",
      dateTo: "",
      durationData: [],
      breakTypeData: [],
      plcmBreakTypes: [],
      
      showEditor: false,
      entityMap: {},
      spotListTime: 0,
      mapping: [],
      metadata: {},
      dataId: 0,
      //
      allMedia: [],
      selectedMedia: [],
      allTimeslots: [],
      selectedTimeslots: [],
      allPG: [],
      selectedPG: [],
      allGrids: [],
      selectedGrids: [],
      allCats: [],
      selectedCats: [],
      onlyBooked: true,
      selectedDates: [],
      //
      dates: [],
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      widths: [],
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],

      isoDates: [],
      fontSize: {},
      limitDays: 60,
      spotsPlacement: [],
      
      spots: 0,
      markedSpots: 0,
      markedGRP: 0,
      shown: false,
      showBreakView: false,
      
      selectedRef: "",
      selectedGrid: {},
      loader: {},
      getBGColor,
      getAppStyle,
      getBGStyle2,
      printTime2,
      printTimeHHMM,
      printTimeMMSS,
      loadingActive: false
    }
  },
  beforeUpdate: function () {
  	this.getOpenThisModule();
  },

  methods: {
    getOpenThisModule() 
    {
    	this.openThisModule = this.$route.query.toOpen;
    	return this.openThisModule;
    },
    getRawKey(brk)
    {
   		//let gridId = brk.dayGridId ? brk.dayGridId: brk.gridId; 
   		return (brk.dayGridId?(brk.dayGridId+"."):"")+brk.gridId+":"+brk.mediaId+":"+brk.breakNo+":"+brk.breakTypeId+":"+brk.date;
    },
	deleteSpot(toDelete)
	  	{
	  	    let that = this;
	  	    
			let time = new Date().getTime();
			var bookRequest = { "ids": toDelete };
			
	  		//console.log( bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId);
			//console.log( JSON.stringify(toDelete ));
			HTTP.put( bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0", bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                let deleted = response.data;
	                console.log("done "+deleted.length+" cancellations in " + dur +"ms => "+(dur/deleted.length)+"ms/spot");
	                if ( deleted && deleted.length>0 && deleted[0].id)
					{
						let date = deleted[0].date;
						for ( let grid in that.grid.dayStructure[date] )
			    		{
							let gridEl = that.grid.dayStructure[date][grid];
							for ( let brk in gridEl.breakList )
			    			{
								let breakStruct = gridEl.breakList[brk];
								if ( breakStruct && breakStruct.spots)
								{
									for ( let spot in breakStruct.spots )
									{
										breakStruct.spots = breakStruct.spots.filter(p=>p.id!=deleted[0].id);
									}
								}
					    	}
					    }
	                }
		            that.showProgressBar = false;
	                
	            }).catch(e => {
	            		that.showProgressBar = false;
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });
	  	},
    getRef(idx1,idx2) { return "SSL_"+idx1+"."+idx2;},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vH - 200px); padding: 1ex;"
    },
    getSlotClass(grid, selectedPrograms) {
		return "transition slot ";
	},
	markBreak( brk )
	{
		
		brk.marked = !brk.marked;
		this.markedSpots = this.selectedSpots( false).length
		this.$forceUpdate();
	},
    sum( spots)
	{
		let dur = 0;
		for ( let i in spots )
		{
			dur += spots[i].duration;
		}
		return dur;
	},
	loaded()
	{
		this.loadedAlready++;
	},
	setDates(d)
	    {
	    
	    	if ( d[0] ==='R')
	    	{
				let from = new Date( d[1]);
				let to = new Date( d[2]);
				this.selectedDates = this.grid.days.filter(p=>new Date(p)>=from && new Date(p)<=to )
			}
			if ( d[0] ==='D')
	    	{
				let x = [...d];
    			x.shift();
				this.selectedDates = x;
			}
			this.dates = d;
	    	
	    },
	moveSpots (dndType, spots, breakIdentifier, sourceIdentifier, fromId, toId) 
     {
		
		//alert( "moveSpots:: "+ breakIdentifier +" <-- "+ sourceIdentifier)
		if ( !spots || !breakIdentifier)
		{
			return;
		} 
        let that = this;
        let time = new Date().getTime();
        let n = 1;

        let fromTo = { spots: spots, setToStatusId: 1, line: breakIdentifier };
        //console.log( bngAPI+"/moveSingleSpot/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/"+dndType );
        //console.log( JSON.stringify( fromTo));
        HTTP.put( bngAPI+"/moveSingleSpot/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/"+dndType, fromTo)
            .then( response => 
            { 
            	
            	let breakList = response.data.touchedBreaks;
                //alert( JSON.stringify( breakList))
                let dur = (new Date().getTime()-time);                
	            //console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
	            
				that.reloadBreaks(breakList);
                
            }).catch(e => {
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });

	},
	bookMarked()
    {
		
		let spots = this.selectedSpots(false);
		//alert( spots.length)
    	let bookReq = {placement: {...this.placement}, lines: spots};
    	//alert( JSON.stringify( bookReq))
    	if ( !this.businessType || !this.businessType.id )
    	{
    		that.showProgressBar = false;
    		that.$toast.error("No businesstype selected", 'Error', { position: "topRight" });
    		return;
    	}
    	let myId=0;
        let n = bookReq.lines.length;
    	let time = new Date().getTime();
    	let that = this;
    	let copyId = ( this.copy && this.copy.id ) ? this.copy.id: 0;
    	bookReq.placement.duration = this.duration;
    	bookReq.status = {...this.selectedBookStatus};
    	delete bookReq.status.label;
    	//console.log( bngAPI+"/book/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+copyId);
    	HTTP.put( bngAPI+"/book/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+this.businessType.id+"/"+copyId+"/"+myId, bookReq)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                
	                //console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
	                that.reloadBreaks( spots);
	                
	            }).catch(e => {
	            	   that.showProgressBar = false;
					   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
					   
	            });

    	
    },
	reloadBreaks(breakLines)
	{
		//alert( "rb: "+ JSON.stringify( breakLines));
		let that = this;
        var breakArr = new Array();
        for ( let ii in breakLines )
        {
        	let breakId = breakLines[ii];
	        breakArr.push( breakId);
        }
       
        let lineInput = { lines: breakLines }
        
        HTTP.post( invAPI+"/getInventoryForBreaks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, lineInput)
        		 .then( response => 
                 { 
                   	let breaksToReplace = response.data;
                   	
                   	for ( var i in breaksToReplace )
                   	{
                   		let brk = breaksToReplace[i];
                   		//alert( JSON.stringify( brk.key))
                   		if ( brk && brk.brk )
                   		{
                   			//let bIdent = brk.brk.identifier.indexOf('.');
                   			let key = brk.key;
                   			//alert(JSON.stringify(brk.brk.date))
 							let grid = that.grid.dayStructure[brk.brk.date].find( p=>p.breakList.find(x=>x.key===key));
							//alert(JSON.stringify(grid))
							let brkX = grid.breakList.find( p=>p.key===key);
							
							//Object.assign(brkX, brk);
							brkX.spots = brk.spots;

							//alert(brk.key+"-->"+that.breakKeys[ brk.key ])
	                   	}
                   	}
                   	that.getUniqueDates( {...that.grid});

					
                 }).catch(e => {
					    console.log(e)
                        that.$toast.error("loading inventory: " + e.response.data, 'Error', { position: "topRight" });

              		 });
	},
	sumGrid( breaks)
	{
		let dur = 0;
		for ( let i in breaks )
		{
			dur += this.sum(breaks[i].spots);
		}
		return dur;
	},
	getTreshold( brk)
    {
		return 0;
	},
    getBreakCode(adSpace, brk, fullBrk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
       
      	return code;
      },
    getUniqueDates( grid)
    {
		this.startLoader(); 
		this.allReady = false;
		
		this.grid = grid;
		this.allPG = grid.pricegroups;
		this.allTimeslots = grid.dayParts.map(p=> {return {id: p.id, label: p.name}});
		this.allPG.sort( (a, b) => a - b);
		this.uniqueDays = grid.days;
		this.dateFrom = this.uniqueDays[0];
		this.dateTo = this.uniqueDays[ this.uniqueDays.length-1];
		this.dates=['R', this.dateFrom, this.dateTo];
		this.dateFrom = new Date(this.dateFrom);//.toISOString().split('T')[0]
		this.dateTo = new Date(this.dateTo);
		
		this.allReady = true;
		return this.uniqueDays;
	},
    limit(dates)
     {
     	let maxLen = this.limitDays;
        if ( dates.length > maxLen )
        {
        	return dates.slice().splice( 0, maxLen );
        }
        return dates;
     },
     getPercent( spots, targetType, target)
    {
    	return target==0? 100: Math.round(10000*spots/target)/100;
    }, 
    getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
    fmt( val)
    {
       return formatNumber( val );
    },
    
    getWidth() 
    {
    	let max = window.innerWidth;
    	
    	let avail = (max * 0.55) - 210;

    	return avail;
    },
    getHeight() 
    {
    	let max = window.innerHeight;
    	
    	let avail = (max)-120;

    	return avail;
    },
    calcHeight()
    {
    	let mh = window.innerHeight;
    	return "height: " + (mh - (mh*this.treeHheight)*.01)+"px;"
    },
    
    printAdSpace( breakType, adSpace)
    {
    	if ( adSpace.label )
    	{
    		return adSpace.label;
    	}
    	if ( adSpace.no === 0 )
    	{
    		return breakType.shortname;
    	}
    	if ( adSpace.no === 999 )
    	{
    		return breakType.shortname;
    	}
    	return breakType.shortname+"-"+adSpace.no;
    },
    startDrag (evt, source) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('fromBreak', source)
	},
	onDrop (evt, target) {
		const source = evt.dataTransfer.getData('fromBreak')
		if ( source )
		{
			var fromTo = new Array();
			fromTo.push( source);
			fromTo.push( target);
			this.$emit("bookMove", fromTo )
		}
		const spots = evt.dataTransfer.getData('spots')
		if ( spots )
		{
			const setToStatusId = evt.dataTransfer.getData('setToStatusId');
			fromTo = { spots: JSON.parse(spots), setToStatusId: setToStatusId?setToStatusId:0, line: target };
			this.$emit("moveSingleSpot", fromTo )
		}
		
	},
    nodeRClicked(event, key, channel, day)
    {
        event.preventDefault();
        this.$refs.menu.open(event, { key: key, channel: channel, day: day} )
    },
    openBreak( event, ref ) {
    	this.selectedRef = ref;
    	this.$refs.menu.close();
    	this.$refs.breakView.open(ref);
    },
    closeBreakView() {
    	this.$refs.breakView.close();
    },
    getMainStyle()
    {
    	return "padding: 10pt 10pt 10pt 10pt; float: left; width: 75%; height: "+this.getHeight()+"px; overflow-y: scroll;";
    },
   
    breakTypeVisible( typeId )
    {
    	return this.inputBreakTypeIds.includes(typeId);
    },
    selectedSpots(unselect)
    {
    	var spots = new Array()
    	for ( let day in this.grid.days )
    	{
			let date = this.grid.days[day];
			for ( let grid in this.grid.dayStructure[date] )
    		{
				let gridEl = this.grid.dayStructure[date][grid];
				for ( let brk in gridEl.breakList )
    			{
					let breakStruct = gridEl.breakList[brk]
		    		if ( breakStruct.marked )
		    		{
		    		    if ( unselect )
		    		    {
		    		    	breakStruct.marked = false;
		    		    }
		    			spots.push( breakStruct.key );
		    		}
		    	}
		    }
    	}
    	return spots;
    },
   
    cancelMarked()
    {
        var spots = this.selectedSpots(true);
        //alert( JSON.stringify( spots))
    	this.cancelMarkedInternal( spots )
    },
    cancelMarkedInternal(spots)
    {
        let n = spots.length;
    	let time = new Date().getTime();
    	let that = this;
    	//alert( JSON.stringify( spots))
    	//alert( bngAPI+"/cancel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+null+"/"+this.placement.id)
    	HTTP.put( bngAPI+"/cancel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/"+this.placement.id, spots)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                
	                //console.log("done "+n+" cancellations in " + dur +"ms => "+(dur/n)+"ms/spot");
	                that.reloadBreaks( spots);       
	                
	            }).catch(e => {
	                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });

    	
    },
    getBreakId( day, grid, adspace, weekdays )
    {
    	if ( adspace.label )
    	{
    		let wd = this.weekdays[day];
        	let wdBit = Math.round(Math.log(wd)/Math.log(2));
    		return grid.adspacePerWeekday[wdBit].find( p=>p.label===adspace.label).id;
    	}
    },
    getKey(channel, grid, adspace, breaktype, day)
    {
    	return getRawKey( channel, grid.detail.id, adspace.no, breaktype.id, this.isoDates[ day]);
    },

    getDayDiff( d, start)
    {
    	let oneDay = 1000 * 60 * 60 * 24;
    	let day = Math.floor(d-start / oneDay);
    	return day;
    },
    hasExcl( channel, grid, adspace, breaktype, day)
    {
		let isoDay = this.isoDates[day];
    	
    	if ( grid.forbidden && grid.forbidden.includes( isoDay))
    	{
    		return true;
    	}
    	return false;
    },
    
    getStyleForDayTable()
    {
    	return "float: top left; display: flex; width: "+this.getWidth()+"px !important; overflow-x: auto;"
    },
    getStyle(name, channel, slot, grid, date, adspace, breaktype)
    {
        var w = 30;
        var h = 1;
        if ( grid && name != 'day' )
        {
        	h = this.getNumberOfBreaks( grid.adspace);
        	if ( name != 'full' )
        	{ 
        		h += 2;
        	}
        }
        else if ( channel && (slot === 0 || slot) )
        {
            h = 0;
        	let grids = this.gridData[channel][slot];
        	for ( var g in grids )
        	{
        		h += this.getNumberOfBreaks( grids[g].adspace);
        		h+=2;
        	}
        	
        }
        if ( name === 'channel' )
        {
        	w=40;
    	}
    	if ( name === 'break' )
        {
        	w=40;
    	}
    	if ( name === 'program' )
        {
        	w=70;
    	}
    	//console.log( channel +"/"+name+"/"+grid+"/"+date);
    	if ( breaktype )
    	{
    		let key = this.getKey(channel, grid, adspace, breaktype, date);
    		let g = this.consumedBreak[ key ];
    		if ( g > breaktype.maxAdTimeISOInSeconds )
            {
    			return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; background-color: #fcc; color: #444;";
    		}
    		g = this.spotsBreakTotal[ key ];
    		if ( g > breaktype.maxAds )
            {
    			return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; background-color: #fbb; color: #444;";
    		}
    	}
    	else if ( channel && date && grid && name == 'day' )
    	{
    		let day = channel+"."+this.isoDates[date];
            let gridKey = day+"."+grid.detail.id;
            //console.log( gridKey);
            let g = this.consumedGrid[ gridKey ];
            //console.log( g);
            let l = g ? g: 0;
            //console.log( "grid: "+ grid.detail.name+ "-> " + grid.adDurationInSec+ " --> " + l); 
            if ( g > grid.adDurationInSec )
            {
    			return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; background-color: #fcc;";
    		}
    	}
    	
    	if ( name == 'full' )
    	{
    		return "height: "+(14*h)+"pt; width: 100% !important;";
    	}
    	return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; color: #000;";
    },
    getStyle4Break(channel, grid, date, adspace, breaktype)
    {
    	if ( breaktype )
    	{
    		let key = this.getKey(channel, grid, adspace, breaktype, date);
    		let g = this.consumedBreak[ key ];
    		if ( g > breaktype.maxAdTimeISOInSeconds )
            {
    			return "background-color: #fcc; color: #444;";
    		}
    		g = this.spotsBreakTotal[ key ];
    		if ( g > breaktype.maxAds )
            {
    			return "background-color: #fbb; color: #444;";
    		}
    	}
    	
    	return "";
    },
    getStyle2(name, channel, slot, grid)
    {
        var w = 30;
        var h = 1;
        if ( grid )
        {
        	h = this.getNumberOfBreaks( grid.adspace);
        }
        else if ( channel && slot )
        {
            h = 0;
        	let grids = this.gridData[channel][slot];
        	for ( var g in grids )
        	{
        		h += this.getNumberOfBreaks( grids[g].adspace);
        	}
        }
        if ( name === 'channel' )
        {
        	w=40;
    	}
    	if ( name === 'break' )
        {
        	w=40;
    	}
    	if ( name === 'program' )
        {
        	w=70;
    	}
    	if ( name == 'full' )
    	{
    		return "height: "+(28*h)+"pt; width: 100% !important";
    	}
    	return "height: "+(28*h)+"pt; width: "+w+"pt !important; valign: bottom !important";
    },
    getNumberOfBreaks(adspace)
    {
        var count = 0;
    	for ( var i in adspace)
    	{
    		let space = adspace[i];
    		let types = this.filter( space.breakTypes);
    		for ( var bt in types)
    		{
    		  let breakType = types[bt];
    		  if ( this.inputBreakTypeIds.includes( breakType.id ))
    		  {
    		  	count++;
    		  }
    		} 
    	}
    	return count;
    },
    getDates()
    {
    	
    },
    dayStyle() {
    	return "float: left; width: 4%; ";
    },
    openEntity( event, entity, id )
     {
        let that = this;
        //console.log(fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+id)
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	      
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
     },
    closeEditor()
    {
         this.showEditor = false;
    },
    
	weekdaySet(weekdays, date)
	{
		let wd = this.weekdays[date];
    	return ( weekdays & wd )
	},
	filter( gridList)
	{
		let tmp = gridList;
		if ( this.onlyBooked)
		{
			tmp =  tmp.filter(g => g.breakList.find(b=>b.spots && b.spots.length));
		}
		this.uniqueDays = this.grid.days;
		if ( this.selectedDates && this.selectedDates.length)
		{
			tmp =  tmp.filter(g => g.breakList.find(b=>this.selectedDates.includes(b.brk.date)));
			this.uniqueDays = this.uniqueDays.filter( d=>this.selectedDates.includes(d));
			//alert( JSON.stringify( this.uniqueDays))
		}
		if ( this.selectedPG && this.selectedPG.length > 0 )
     	{
     		tmp =  tmp.filter(g => g.breakList.find(b=>this.selectedPG.includes(b.brk.pricegroup)));
  
     	}
     	if ( this.selectedTimeslots && this.selectedTimeslots.length > 0 )
     	{
			let ts = this.selectedTimeslots.map(p=>p.id);
     		tmp =  tmp.filter(g => g.breakList.find(b=>ts.includes(b.dayPartId)));
  
     	}
		return tmp;
	},
    setPlacement(info) { 
       let that = this;
       this.startLoader();
       //console.log("CHECKPT setPlacement()");
       if ( info.placement.id != that.placement.id )
       {
       		this.clearFilter();
       }
       that.placement = info.placement;
       let flt = { media: this.selectedMedia, grids: this.selectedGrids, pg: this.selectedPG, timeslots: this.selectedTimeslots, categs: this.selectedCats };
      
       that.placementFrom = new Date( this.placement.fromDate);
       that.placementTo = new Date( this.placement.untilDate);
       that.inventory = info.inventory;
       that.plcmBreakTypes = new Array();
       that.breakTypeData = info.allBreakTypes;
       for ( var bt in that.breakTypeData )
       {
          let breakType = that.breakTypeData[bt];
          //alert(bt +"-->"+ JSON.stringify(breakType));
          if ( !that.placement.breakTypes || that.placement.breakTypes.length==0 || that.placement.breakTypes.includes( breakType.id) )
          {
          	that.plcmBreakTypes.push( breakType);
          }
       }
       //console.log("CHECKPT 4");
       
	   that.breakTypeData = that.plcmBreakTypes.slice();
	   //console.log(JSON.stringify(that.breakTypeData));
	   that.getAdBreaks(that.placement);       
    },
    printTimeslot( elem)
    {
    	return printTime2(elem.starttime) + " - " + printTime2(elem.starttime+elem.durationInSec);
    },
    doFilterGrid()
    {
		return this.grid;
    },
    getGridData( useThisGrid, keepFilters)
    {

        
    },
    getAdBreaks(plcm) { 
       this.startLoader();
       this.getDates();
       this.placements = [];
       this.marked = new Array();
       this.markedSpots = 0;
       this.markedGRP = 0;
       this.showPlacementGrid = true;
       this.doFilterGrid();     
    },
    clearFilter() { 
       this.startLoader();
       this.selectedMedia = new Array();
       this.selectedGrids = new Array();
       this.selectedPG = new Array();
       this.selectedTimeslots = new Array();
       this.selectedCats = new Array();
       this.selectedSpotReasons = new Array();
       this.doFilterGrid();  
    },

   printTime(time)
   {
       		return printTimeHHMM(time);
   },
   startLoader()
    {
		/*
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
	    */
    },
	stopLoader()
	{
		//alert(3)
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	
  },
  computed: {
  	spotsSelected: { 
  	    get() {
	        var spots = new Array()
	    	for ( var x in this.marked )
	    	{
	    		if ( this.marked[x] )
	    		{
	    			spots.push( x );
	    		}
	    	}
	    	return spots;
      }
  	}
  },
  watch: {
	gridInput: function() { this.uniqueDays = this.getUniqueDates( this.gridInput)}
  },
  created() {
	  this.allReady = false;
	  this.uniqueDays = this.getUniqueDates( this.gridInput);
	  let that=this;
      this.$nextTick(function () {
	  	timers.push( setTimeout( that.stopLoader, 100));
	  	});
  },
  beforeDestroy() {
  },
  updated() {
	

	let that=this;
	this.$nextTick(function () {
  	//timers.push( setTimeout( that.stopLoader, 100));
  	});
  }
}
</script>
<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.xtransition {
 	transition: all .2s;
   -webkit-transition: all .2s;
 }
 .appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
}  
.slot {
	box-shadow: 9pt 9pt 5pt 0 #99c;
	border: 1pt ridge #99c;
	margin-top: 0px;
	margin-bottom: 20pt;
	margin-right: 4pt;
}
.invisible {
	visibility: hidden;
	position: absolute;
	color: #fff;
	background-color: #fff;
}
.invisibleBreak {
	display: hidden;
	height:0pt;
    -webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
}
.transition {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.xtransitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.BMday {
  display: table-cell;
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.dontWrap90 {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 50%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}
.BMadbreak {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #888;
  background-color: #fff; 
  text-align: center;
  height: 100%;
  margin: 0pt;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
  position: relative; 
  height: 14pt; 
  width: 30pt !important; 
}
.BMnoadbreak {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #bbb;
  background-color: #eee;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.BMnoadbreakExcl {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #bbb;
  background-color: #fcc;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.BMadbreak1 {
	display: inline-block; 
	width: 66%;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 2pt;
   
    margin: 0pt;
    height: 100%;
    margin: 0pt;
}
.BMadbreak2 {
	display: inline-block; 
	width: 28% !important; 
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
	height: 100%;
	margin: 0pt;
    padding: 0pt;
}
.BMadbreakMarked {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #f0c;
  background-color: #bcc; 
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  font-size: 7pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.BMbreak:hover {
  color: #fff !important;
  background-color: #da58da !important; 
  cursor: pointer;
}
.BMadbreak:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMadbreakMarked:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMheader { 
  display: block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 8pt; 
  font-weight: bold;
  
  //overflow-x: hidden;
  //overflow-y: hidden;
 
  white-space: nowrap; /* Don't forget this one */ 
  //text-overflow: ellipsis;  
  
  //border-bottom: 1pt solid #ccc;
  background-color: #eef; 
 }
.BMheaderInv {
	color: transparent;
}
.BMprogram {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
	//border: 1pt solid #fcc; 
	position:relative;
	z-index: 0;
}
.BMprogram:hover {
	color: #fff !important; 
    background-color: #888 !important; 
	cursor: pointer;
	
}
.puInfo { padding-left: 2em; z-index: 999; }
.BMtime {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
}
.BMuserTime {
	color: #888;
	padding-left: 2pt;
}
.BMinfo {
	background-color: #58dada; 
}
.BMbreak {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
}
.BMheadLine {
  //color: #fff !important;
  background-color: #58dada !important; 
}
.todrag {
 display: block;
 width: 100%; 
 height: 100%;
}
.BMheaderDay { 
  font-size: 7pt; 
  display: table-cell;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  align-items: center;
  justify-content: center;  
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
  overflow-x: hidden;
  color: #000;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
 }
 .BMHeadActions:hover {
 	cursor: pointer;
 	color: #fff !important; 
    background-color: #888 !important; 
 }
 .BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
 .colored {
  background-color: #eef; 
 }
 .RDBreakDur {
    display: inline-flex;
    //float: right;
    padding-left: 5pt;

	font-weight: bold;
	//background-color: #fff;
}
.RDGridDurProgress {
	position: relative;
    display: inline-flex;
    float: right;
    //border: 1pt solid grey;
    align-items: center;
    justify-content: top;
    vertical-align: top !important;
    padding-top: 3pt;
    padding-left: 10pt;
    padding-right: 20pt;
 	padding-bottom: 0pt;
	font-weight: bold;
}
.RDBreakDurProgress {
	display: inline-block;
    float: right;
    align-items: center;
    justify-content: top;
    vertical-align: top !important;
    padding-left: 5pt;
    padding-right: 5pt;
    margin-top: 1pt;
	font-weight: bold;

}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #888;
	font-weight: bold;
	vertical-align: top !important;
}
.RDProgramTime2 {
    color: #888;
}
.RDProgram {
    display: flex;
	font-size: 14px;
	font-weight: bold;
	position: relative;
	height: 33pt;
	//background-color: #fff;
}
.RDBreakTitle {
	
	font-weight: bold;
	
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #888;
	font-weight: bold;
	//background-color: #fff;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	//background-color: #fff;
}
.RDBreak {
	font-size: 11px;
	font-weight: bold;
	//background-color: #fff;
	border-top: 1px outset #aaa;
	text-align: left;
	padding-left: 6pt;
	
	 white-space: nowrap; /* Don't forget this one */
     text-overflow: ellipsis; 
     overflow: hidden;
     width: 100%;
}
 .iButton {
    background-color: transparent;
    border: none;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.iButton:hover {
    color: #fff !important;
  	background-color: rgb(88, 218, 218);; 
}  
</style>
